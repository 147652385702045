import React from "react";
import T3 from "../images/T3.jpg";
import "./Try.css";

function Try3() {
  return (
    <>
      <section className="container Tcontainer" style={{marginTop:"-80px"}}>
        <div className="Ttestimonial">         
          <div className="testi-content">
            <div className="Tslide">
              <img src={T3} alt="aboutimage" className="Timage" />
              <p>
                When I am in a pinch or need to move freight, my first choice is
                AJ Logistics, I can also call them 24x7 and get an answer if I
                have a question about my load.
              </p>
              <i className="bx bxs-quote-alt-left quote-icon"></i>
              <div className="Tdetails">
                <span className="Tname">Tushar</span>
                <span className="Tjob">Chennai</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Try3;
