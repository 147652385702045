import React from "react";
import Box from "./Box";
import image1 from "./images/s1.png";
import image2 from "./images/s2.png";
import image3 from "./images/s3.png";
import image4 from "./images/s4.png";
import image5 from "./images/s5.png";

function Services() {
  return (
    <div id="services">
      <div className="s-heading">
        <h1>Services</h1>
        <p>Here are some of services we provide</p>
      </div>
      <br></br>
      <div className="b-container">
          <Box
            image={image2}
            alte="image2"
            button="Surface Express"
            para="Surface express service offers customized value added services to meet customer’s requirements. We at AJ Logistics provide surface express distribution to respect our customer's time and the safety of the product as well."
          />
        <Box
          image={image5}
          alte="image5"
          button="Full-Truck Load"
          para="Our another core offering is Full-Truck load (FTL) services, FTL is a shipping mode where a truck service carries one dedicated shipment. We have a wide variety of vehicles with different  payload capacities and dimensions."
        />
        <Box
          image={image3}
          alte="image3"
          button="Multi-Modal"
          para="We provide cost-effective transportation solutions with intention to reduce carbon footprint. We at AJ Logistics offer rail and multimodal services to our discerning & delighted customers."
        />
      </div>
      <div className="services">
        <div className="b-container">
          <Box
            image={image4}
            alte="image4"
            button="Air Cargo"
            para="It is considered as the fastest method for logistics purposes, We possess highly-experienced & skilled employees to serve growing list of customers as we offer a high-quality of air freight services with premium facilities."
          />
          <Box
            image={image1}
            alte="image1"
            button="Coastal/Ship"
            para="We provide maritime logistics which is a well known shipping that has always been regarded as an important transport sector of national activities in all maritime countries, and it is well suited for transportation of bulk cargoes at low cost."
          />
          {/* <Box image={image3} alte='image6' button='Sixth' />  */}
        </div>
      </div>
    </div>
  );
}

export default Services;
