import React from "react";
import T4 from "../images/T4.jpg";
import "./Try.css";

function Try4() {
  return (
    <>
      <section className="container Tcontainer" style={{marginTop:"-80px"}}>
        <div className="Ttestimonial">          
          <div className="testi-content">
            <div className="Tslide">
              <img src={T4} alt="aboutimage" className="Timage" />
              <p>
                The extremely fast, friendly, no-nonsense, and reliable way of
                doing business has made AJ Logistics our most trusted
                transportation partner.
              </p>
              <i className="bx bxs-quote-alt-left quote-icon"></i>
              <div className="Tdetails">
                <span className="Tname">Vijendera Maharia</span>
                <span className="Tjob">Pune</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Try4;
