import React from "react";
import T1 from "../images/T1.jpg";
import "./Try.css";

function Try1() {
  return (
    <>
      <section className="container Tcontainer" style={{marginTop:"-80px"}}>
        <div className="Ttestimonial">          
          <div className="testi-content">
            <div className="Tslide">
              <img src={T1} alt="aboutimage" className="Timage" />
              <p>
                I have been doing business with AJ Logistics. I have never been
                disappointed with the service I receive. They are one of the
                carriers I can always count on to be honest.
              </p>
              <i className="bx bxs-quote-alt-left quote-icon"></i>
              <div className="Tdetails">
                <span className="Tname">Sandeep Sharma</span>
                <span className="Tjob">Guwahati</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Try1;
