import React from "react";

function Footer() {
  return (
    <>
      <footer>
        <div id="footer" className="footer">
          <div className="start">Please get in touch and our team will answer all your questions.</div><br/>
          <div className="row">
            <div className="col-contact">
            <div>
              <div className="fa fa-envelope"></div>
              <div><br/> ajlogisticsggn@gmail.com<br/></div>
            </div>
            </div>
          
            <div className="col-contact" style={{paddingLeft:"50px",paddingRight:"50px"}}>
            <div>
              <div className="fa fa-map-marker"></div>
              <div><br/>AJ LOGISTICS , BILASPUR - PATAUDI ROAD<br/>OM SHANTI BHORA KALAN , GURGAON (122413)
              </div>
            </div>
            </div>

            <div className="col-contact">
            <div>
              <div className="fa fa-phone"></div>
              <div><br/> +91 89710 81354 <br/> +91 90535 81354</div>
            </div>
            </div>
          </div>

          <div className="row-copyright">
            <hr style={{marginLeft:"15%",marginRight:"15%",border:"none",borderTop:"1px solid gray",color:"whitesmoke"}}></hr><br/><br/>
            Copyright © 2021 AJ Logistics - All rights reserved <br /><br />
            ||||||||||||
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
