import React from "react";
import {Link} from 'react-scroll';
import A123 from "./images/112233.jpg";
import Navbar from "./Navbar";

function Header() {
  return (
    <>
      <div id="main"             >
        <Navbar />
        <div className="name">
          <h1>
            <span>AJ Logistics</span>
          </h1>
          <p className="details">
            A most reliable and dependable partner in the transportation
            industry.
          </p>
          <div className="header-btns">
            <Link to='footer' className="cv-btn">Book Now</Link>
            <Link to='footer' className="cv-btn1">Any Query</Link>
          </div>
        </div>
        <div className="arrow"></div>
      </div>
    </>
  );
}

export default Header;
