import React from "react";
import T2 from "../images/T2.jpg";
import "./Try.css";

function Try2() {
  return (
    <>
      <section className="container Tcontainer" style={{marginTop:"-80px"}}>
        <div className="Ttestimonial">         
          <div className="testi-content">
            <div className="Tslide">
              <img src={T2} alt="aboutimage" className="Timage" />
              <p>
                We recently used their services with excellent pricing, their
                staff is knowledgeable, friendly and great to work with.
              </p>
              <i className="bx bxs-quote-alt-left quote-icon"></i>
              <div className="Tdetails">
                <span className="Tname">Jagjeet Singh</span>
                <span className="Tjob">Ranchi</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Try2;
