import React from "react";
import aboutimage from "./images/about1.png";

function Features() {
  return (
    <>
      <div id="features">
        <div className="features-model">
          <img src={aboutimage} alt="aboutimage" />
        </div>
        <div className="features-text"> 
        
          <h2>AJ Logistics</h2>
          <h3>Choose us and we will get your products there.</h3>
          <p>
            AJ Logistics, a well-known trucking company that has been providing
            its services to customers. We make sure that your goods reach their
            destination in a timely manner and without any damage.
          <br/>
            We also aims
            to be the best at what we do by focusing on excellence and making
            sure that we are always learning new ways of doing things better.
            <br/>
            In keeping with its principles of being an agile, responsible,
            collaborative and customer-centric, we outmost believe in excellence
            and delivery of products safely at your doorsteps.
            <br/>
            Everything is easy to handle and can be customized to meet customer
            needs. We are most reliable and dependable partner in the
            transportation industry. Our company has a reasonable price, which
            gives them an edge over the competitors. We are easily available in
            the market, online and offline.
          </p>          
          
          <h2><span style={{text:'#d0ff00'}}>___________</span></h2>
          <br></br>
        </div>
      </div>
    </>
  );
}

export default Features;
