import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import Try1 from "./Try1";
import Try2 from "./Try2";
import Try3 from "./Try3";
import Try4 from "./Try4";
import Try5 from "./Try5";

export default function App() {
  return (
    <>
    <div id="testimonial" className="s-heading" style={{marginTop:"60px"}}>
            <h1>Testimonials</h1>
            <p>What our clients says :</p>
          </div>
      <Swiper
        rewind={true}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><Try1 /></SwiperSlide>
        <SwiperSlide><Try2/></SwiperSlide>
        <SwiperSlide><Try3/></SwiperSlide>
        <SwiperSlide><Try4/></SwiperSlide>
        <SwiperSlide><Try5/></SwiperSlide>
      </Swiper>
    </>
  );
}
