import React from "react";
import Features from "./Features";

function About() {
  return (
    <>
    <div id="about" style={{marginBottom:20}}>
      <div  className="f-heading">
        <h1>About Us</h1>
        <p>Here are some information to know us better </p>        
      </div>
      <Features/>
      </div>
    </>
  );
}

export default About;
