import React from "react";
import T5 from "../images/T5.jpg";
import "./Try.css";

function Try5() {
  return (
    <>
      <section className="container Tcontainer" style={{marginTop:"-80px"}}>
        <div className="Ttestimonial">          
          <div className="testi-content">
            <div className="Tslide">
              <img src={T5} alt="aboutimage" className="Timage" />
              <p>
                All the staff is easy to get along with, understands all of our
                needs, and goes above and beyond to ensure we’re taken care of,
                and that the job always gets done the right way.
              </p>
              <i className="bx bxs-quote-alt-left quote-icon"></i>
              <div className="Tdetails">
                <span className="Tname">Gurdeep Sidhu</span>
                <span className="Tjob">Indore</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Try5;
