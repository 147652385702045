import React from "react";
import truck from "./images/Trucks.png";

function Image() {
  return (
    <>
      <div className="image">
        <img src={truck} alt="Trucks" />
      </div>
    </>
  );
}

export default Image;
