import About from './Components/About';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Services from './Components/Services';
import Test from './Components/Testimonial/Test';
import Image from './Components/Image';

function App() {
  return (
    <>
      <Header/>
      <About/>
      <Services/>
      <Test/>
      <Image/>
      <Footer/>
    </>
  );
}

export default App;
