import React from 'react';
import { useState } from 'react';
import {Link} from 'react-scroll';
import logo1 from './images/logo1.png'

function Navbar() {

  const [display, setDisplay] = useState(false);

  const closeMenu = () => {
    setDisplay(false);
    
  }

  return (
    <div>
      <nav>
         <Link to= 'main' className= 'logo'>
             <img src={logo1} alt='logo'/>
         </Link>
         <input className= 'menu-btn' checked={display} type= 'checkbox' id='menu-btn'/>
         <label className= 'menu-icon' htmlFor= 'menu-btn'>
             <span onClick={() =>setDisplay(display === false? true : false)} className= 'nav-icon'></span>
         </label>
         <ul className='menu'  >
            <li ><Link onClick={() =>closeMenu()} to='main'>Home</Link></li>
            <li><Link onClick={() =>closeMenu()} to="about" >About</Link></li>
            <li><Link onClick={() =>closeMenu()} to="services" >Services</Link></li>
            <li><Link onClick={() =>closeMenu()} to="testimonial" >Testimonial</Link></li>
            <li><Link onClick={() =>closeMenu()} to="footer">Contact</Link></li>

         </ul>
            <Link to='#' className='hey'></Link> 
            <Link to='#' className='hey'></Link> 
            
     </nav>
    </div>

  )
}

export default Navbar;